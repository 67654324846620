.tabla-samsara{
    width: 95%;
    margin: 0 auto;
}
.container-filtros-samsara{
    width: 95%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 15% 15% 10% 40%;
    grid-template-areas: "a b c d";
    grid-gap: 10px;
}