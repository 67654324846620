.table_saldos_old{
    width: 95%;
    margin: 0 auto;
}
.table_saldos_old .sticky-table-table{
    width: 100%;
}
.table_saldos_old td:nth-child(1){
    text-align: center;
}
.table_saldos_old td:nth-child(2){
    text-align: center;
}
.table_saldos_old td:nth-child(4),
.table_saldos_old td:nth-child(5),
.table_saldos_old td:nth-child(6),
.table_saldos_old td:nth-child(7){
    text-align: right;
}
.selec-deuda-type{
    margin-top: 0px;
}
.selec-deuda-type select{
    height: 30px;
    padding: 5px;
}
.filtros_consult_balances_old{
    margin: 0 auto;
    width: 95%;
}
.ocultar-ant-sald{
    display: none;
}
