.TableProducLoad {
  width: 95%;
  margin: 0 auto;
  margin-top: -65px;
}
.TableProducLoad .sticky-table-table {
  width: 100%;
}
.TableProducLoad td:nth-child(3),
.TableProducLoad th:nth-child(3),
.TableProducLoad td:nth-child(4),
.TableProducLoad th:nth-child(4),
.TableProducLoad td:nth-child(5),
.TableProducLoad th:nth-child(5),
.TableProducLoad td:nth-child(6),
.TableProducLoad th:nth-child(6),
.TableProducLoad td:nth-child(7),
.TableProducLoad th:nth-child(7),
.TableProducLoad td:nth-child(8),
.TableProducLoad th:nth-child(8) {
  text-align: center;
}

.TableProducLoad td:nth-child(1),
.TableProducLoad th:nth-child(1) {
  text-align: center;
}

.TableProducLoad td:nth-child(1) img {
  width: 25px;
  height: 25px;
}
.TableProducLoad th:nth-child(1) img {
  width: 25px;
  height: 25px;
}
.grid-load-prod {
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 20%;
  grid-template-areas: "a b c d e";
  grid-gap: 10px;
  margin: 0 auto;
  width: 95%;
  position: relative;
  z-index: 100;
}
.grid-load-prod input {
  height: 40px;
  margin-top: 12px;
}
.grid-load-prod select {
  height: 20px;
  padding: 10px;
}
.item4load {
  margin-top: 6px;
  text-align: left;
  padding-left: 10px;
}
.item4load button {
  padding: 12px;
  width: 70%;
}

.price-load-dta {
  width: 60%;
  margin-left: 3%;
}

.price-load-dta {
  display: grid;
  grid-template-areas: "expen5n expen6 expen7 expen8 expen9 expen10 expen11";
  gap: 20px;
  z-index: 10;
  position: relative;
}



.ticket h2 {
  font-size: 14px;
  font-weight: bold;
  text-align: justify;
}

.ticket td,
.ticket th,
.ticket tr,
.ticket table {
  border-top: 1px solid black;
  border-collapse: collapse;
  background-color: none !important;
}

.ticket td.producto,
.ticket th.producto {
  width: 75px;
  max-width: 75px;
}

.ticket td.cantidad,
.ticket th.cantidad {
  width: 40px;
  max-width: 40px;
  word-break: break-all;
}

.ticket td.precio,
.ticket th.precio {
  width: 40px;
  max-width: 40px;
  word-break: break-all;
}

.ticket .centrado {
  text-align: justify;
  align-content: justify;
}

.ticket .ticket {
  width: 155px;
  max-width: 155px;
}

.ticket img {
  max-width: inherit;
  width: inherit;
}

/*aaaaaa*/
.ticket span,
.ticket p {
  font-size: 14px;
}
.ticket {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 20px;
  /* max-width: 550px; */
}

.centrado {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.detalles {
  font-size: 12px;
  line-height: 1.5;
}

.detalles::before {
  content: attr(data-title);
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.detalles span {
  display: inline-block;
  width: 250px;
}

.msj_product_load {
  width: 100%;
}

.map-table {
  color: rgb(54, 54, 225);
  text-decoration: underline;
  cursor: pointer;
}
.map-table span {
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.todoaaaa {
  width: 75%;
  padding-bottom: 10px;
  /* margin: 0 auto; */
  margin-left: 3%;
}
.alertm {
  padding: 20px;
  background-color: #2196f3;
  color: white;
  width: 100%;
  transition: opacity 0.6s;
}

.closebtn {
  color: white;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
  transition: 0.1s;
  margin-left: 73%;
  position: absolute;
}

.closebtn:hover {
  color: black;
}

.img-product {
  cursor: pointer;
}



.price-load-dta-detail {
  width: 100%;
}

.price-load-dta-detail {
  display: grid;
  grid-template-areas: "expen5n expen6 expen6 expen6 expen6 expen6";
  gap: 20px;
  z-index: 10;
}


.ticket-table {
  border: 1px solid #000;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
}

.ticket-table td {
  padding: 8px;
  border: 1px solid #000;
}

.ticket-table h2 {
  margin: 8px 0;
  font-size: 16px;
}

.centrado {
  text-align: center;
}

.detalles {
  font-size: 14px;
}

.map-table {
  cursor: pointer;
}

.map-table:hover {
  background-color: #f2f2f2;
}


.map-product-load{
  padding-top: 30px;
}


.reconsulta{
  z-index: 11 !important;
  position: absolute;
  cursor: pointer;
  margin-left: 4%;
  margin-top: -.9%;
}
