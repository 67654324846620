.Tablepossec{
    margin-top: -60px;
}
.icono-upload{
    width: 40px;
    height: 50px;
    float: right;
    margin-top: 10px;
    margin-left: -10px;
}
.icono-upload:hover{
    cursor: pointer;
}