.drop-area{
    width: 70%;
    height: auto;
    margin: 0 auto;
    border: 3px dashed red;
    font-size: 18px;
    padding: 10px;
}
.container-upload-csv{
    padding: 10px;
    text-align: center;
}
.file-select{
    padding-top: 5px;
    font-size: 17px;
}
.file-select span{
    font-weight: bold;
}

.drop-load img{
    width: 150px;
}
.drop-load p{
    font-size: 18px;
    color: rgb(24, 38, 113);
    text-align: center;
}
.p-err{
    text-align: center;
    font-size: 18px;
    color: red;
    padding-top: 10px;
    margin-bottom: 0px;
}
.p-colum{
    text-align: center;
    font-size: 18px;
    color: red;
}
.p-colum span{
    font-weight: bold;
}