
  
.centro{
  margin: 0 auto;
}


.drangAndDrop{
  border: dashed red;
  width: 80%;
  margin: 0 auto;
}

.drangAndDrop{
  cursor:pointer;
}

.conenediorAviso{
  width: 650px;
  text-align: justify;
  margin: 0 auto; 
}

.containerPDF-XML{
    margin: 0 auto;
    width: 100%;
    padding-top: 30px;

}


.validadorDoc{
  width: 20%;
  height: 20%;
}

.Instrucciones{
  text-align: justify;
 
}

.pcheck {
  text-align: justify;
}

.SelectCheck{
  margin-left: 9.6%;
}



.xml-pdf-container {
  display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
  
.instrucciones-container  { grid-area: 1 / 3 / 2 / 5; }
.pdf-container { grid-area: 2 / 3 / 3 / 4; }
.xml-container { grid-area: 2 / 4 / 3 / 5; }
.pdf-container2  { grid-area: 3 / 3 / 4 / 5; }
.boton-container { grid-area: 4 / 3 / 5 / 5; } 



.container-proveedor {
  width: 50%;
  margin: 0 auto;
  display: grid;
  padding-top: 20px;
  padding-left: 2.5%;
  grid-gap: 10px;
  gap: 10px;
  position: absolute;
  z-index: 1;
  top: -30px;
  grid-template-areas: "a b c";
}

.div1c{
  grid-area: a;
}
.div2c{
  grid-area: b;
}
.div3c{
  grid-area: c;
}


.TableProveedor{
  width: 95%;
  margin: 0 auto;
}