
/* Tabla de descuentos */
.TableDiscount{
    margin: 0 auto;
    width: 95% !important;
}
.TableDiscount .sticky-table-table{
    width: 100%;
}
.tabledesc{
    margin-top: -60px;
}
.TableDiscount .titletable {
    margin-top: -40px;
    margin-bottom: -40px;
}
.TableDiscount .titletable  h1{
    font-size: 35px !important;
}

.TableDiscount th:nth-child(2) #custom-select,
.TableDiscount th:nth-child(7) #custom-select,
.TableDiscount th:nth-child(8) #custom-select,
.TableDiscount th:nth-child(9) #custom-select{
    width: 100% !important;
    height: 35px;
    font-size: 15px !important;
    text-align: center; 
}

.TableDiscount td:nth-child(1){
    min-width: 100px;
}
.TableDiscount td:nth-child(2),
.TableDiscount td:nth-child(3){
    text-align: center;
}

.TableDiscount td:nth-child(5){
    text-align: center;
}
.TableDiscount td:nth-child(4),
.TableDiscount td:nth-child(6){
    text-align: left;
}
.TableDiscount td:nth-child(7),
.TableDiscount td:nth-child(8){
    text-align: center;
}
.TableDiscount td:nth-child(9){
    text-align: center;
    width: 10%;
}
.formulariodescuentos input{
    height: 50px;
}

.formulariodescuentos input[type=checkbox]{
    width: 20px !important;
    height: 20px;
}

/* campos checbix de sujeto a plazo y dirigido a socio */
.gridds0{   
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 50% 50%;
    grid-template-areas:"a b"    
}
/* campos de fecha inicial y final */
.griddsfechas{
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 50% 50%;
    width: 90%;
    grid-template-areas:"a b"; 
    grid-gap: 10px; 
}
.griddsfechas .fi, .griddsfechas .ff{
    width: 50% !important;
}         
.addpointsalecss .itemdis00 input, 
.addpointsalecss .itemdis01 input,
.addpointsalecss .itemdis5 input
{
    height: 20px !important;
    width: 20px !important;
}

.modalmedium{
    width: 50% !important;
}