.container-videos{
  width: 100%;
}

/* seccion del catalogo */
.container-seccion-videos{
  width: 100%;
}
.catalogo-videos{
  width: 100%;
}
.catalogo-videos .grid-videos{
  margin: 0 auto;
  width: 90%;
  display: grid;
  grid-template-columns: 19% 18% 19% 18% 19%;
  grid-template-areas: 
      "carta1 carta2 carta3 carta4 carta5"
      "carta6 carta7 carta8 carta9 carta10" 
      "carta11 carta12 carta13 carta14 carta15";
  grid-gap: 20px;
}
.catalogo-videos .grid-videos-caratulas{
  margin: 0 auto;
  width: 90%;
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-template-areas: 
      "carta1 carta2 carta3 carta4"
      "carta5 carta6 carta7 carta8" 
      "carta9 carta10 carta11 carta12"
      "carta13 carta14 carta15 carta16"
      "carta17 carta18 carta19 carta20"
      "carta21 carta22 carta23 carta24"
      "carta25 carta26 carta27 carta28"
      "carta29 carta30 carta31 carta32";
  grid-gap: 20px;
}

.carta-vid:hover{
   transform: scale(1.05);
}

.carta-vid{
  border-radius: 25px;
  box-shadow: 7px 0px 7px rgba(0, 0, 0, 0.3);
  background-color: white;
}
.descripcion-carta-vid{
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 15px;
}
.descripcion-carta-vid p{
  height: 100%;
  width: 90%;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
.title-carta-vid{
  text-align: center;
  padding-top: 10px;
  font-size: 18px;
  padding-left: 5px;
  font-weight: bold;
}
.icon-container{
  border-top-right-radius: 25px;
  border-top-left-radius: 25px;  
  text-align: center;
  background-color: rgb(50, 92, 125);
}
.icon-container svg{
  width: 100px;
  height: 100px;
}

.carta-vid :hover{
  cursor: pointer;
}

.carta-vid .img-format{
  width: 100%;
  height: 200px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

.grid-busqueda{
  margin: 0 auto;
  width: 90%;
  display: grid;
  grid-template-columns: 80% 10%;
  grid-template-areas: 
      "a b";
}
.button-seacrh{
  grid-area: b;
}
.button-seacrh button{
  height: 56px;
  background-color: rgb(50, 92, 125);
  border-radius: 10px;
  width: 100%;
  margin-top: -2px;
}

.barra-busqueda{
  grid-area: a;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  text-align: center;
  margin-top: -20px;
}
.barra-busqueda input{
  width: 60%;
  height: 50px;
  text-align: left;
  border-radius: 10px;
  padding: 10px;
}



/* css del reporductor video */
.grid-play{
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-areas: 
      "a b";
  grid-gap: 20px;
  margin-top: -20px;
  height: 70vh;
  margin-bottom: 100px;
}
.reproductor-play{
  grid-area: a;
  width: 100%;
  margin-left: 10px;
  margin-top: 15px;
}

.collage{
  grid-area: b;
  width: 90% !important;
  margin-top: 10px;
  overflow-y: scroll;
}
.recomendados-videos{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;;
}
.video-recom {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 10px;
}
.video-recom img{
  max-width: 60%;
}
.video-recom p{
  padding-left: 5px;
  font-size: 17px;
  text-align: left;
}

.video-recom:hover{
  background-color: #C9CACA;
  cursor: pointer;
}

.titlevid{
  font-size: 45px;
  margin-top: -10px;
  margin-bottom: 0px;
}


@media only screen and (max-width: 1080px){
 
  .catalogo-videos .grid-videos{
    grid-template-columns: 23% 23% 23% 23%;
    grid-template-areas: 
        "carta1 carta2 carta3 carta4" 
        "carta5 carta6 carta7 carta8" 
        "carta9 carta10 carta11 carta12"
        "carta13 carta14 carta15 carta16"
        "carta17 carta18 carta19 carta20"
        "carta21 carta22 carta23 carta24"
        "carta25 carta26 carta27 carta28"
        ;
    grid-gap: 20px;
  }

}

@media only screen and (max-width: 720px){
  .grid-play{
    grid-template-areas: 
        "a a"
        "b b";
  }
  .reproductor-play{
    width: 95% !important;
  }
  .catalogo-videos .grid-videos{
    grid-template-columns: 30% 30% 30%;
    grid-template-areas: 
        "carta1 carta2 carta3"
        "carta4 carta5 carta6" 
        "carta7 carta8 carta9" 
        "carta10 carta11 carta12"
        "carta13 carta14 carta15"
        "carta16 carta17 carta18"
        "carta19 carta20 carta21"
        "carta22 carta23 carta24"
        "carta25 carta26 carta27"
        "carta28 carta29 carta20"
        ;
    grid-gap: 20px;
  }
}

@media only screen and (max-width: 480px){

  .catalogo-videos .grid-videos{
    grid-template-columns: 45% 45%;
    grid-template-areas: 
        "carta1 carta2" 
        "carta3 carta4" 
        "carta5 carta6" 
        "carta7 carta8" 
        "carta9 carta10" 
        "carta11 carta12"
        "carta13 carta14" 
        "carta15 carta16"
        "carta17 carta18"
        "carta19 carta20"
        "carta21 carta22"
        "carta23 carta24"
        "carta25 carta26"
        "carta27 carta28"
        
        ;
    grid-gap: 20px;
  }
  .catalogo-videos .grid-videos-caratulas{
    grid-template-columns: 90%;
    grid-template-areas: 
    "carta1" 
    "carta2" 
    "carta3"
    "carta4" 
    "carta5"
    "carta6" 
    "carta7"
    "carta8" 
    "carta9"
    "carta10" 
    "carta11"
    "carta12"
    "carta13"
    "carta14" 
    "carta15"
    "carta16"
    "carta17"
    "carta18"
    "carta19"
    "carta20"
    "carta21"
    "carta22"
    "carta23"
    "carta24"
    "carta25"
    "carta26"
    "carta27"
    "carta28"    
    ;
    
    grid-gap: 20px;
  }
}


.breadcrumbs-videos {
  text-align: left;
  margin: 0 auto;
  width: 90%;
  font-size: 20px;
  margin-bottom: 20px;
}

.breadcrumbs-videos a {
  text-decoration: none;
  color: black !important;
  padding-left: 15px;
  padding-right: 15px;
}

.breadcrumbs-videos a:hover {
  text-decoration: underline;
  background-color: rgb(26, 55, 77);
  color: white !important;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
  cursor: pointer;
}

.breadcrumbs-videos span {
  color: gray;
  padding-left: 10px;
  padding-right: 10px;
}
