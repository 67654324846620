.modal-content {
    left: 0%;
    right: 0%;
    bottom: 0%;
    background-color: #777070;
    width: auto;
    max-width: 90%;
    padding: 0px;
    border-radius: 4px;
    outline: none;
}

.modal-header {
    display: inline;
    gap: 20px;
    align-self: center;
}

.btn-authorizer {
    margin: 0 auto;
}
.content-folder {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 50% 50%;
    grid-template-areas: "a b";
    padding-bottom: 50px;
}

.folder-select {
    margin: 5px;
    width: 90%;
    margin: 0 auto;
    padding-top: 20px;
    grid-area: a;
}

.files-list {
    padding-top: 20px;
    text-align: start;
    margin: 5px;
    box-shadow: inset;
    overflow-y: auto;
    grid-area: b;
    width: 90%;
    margin: 0 auto;
    max-height: 250px !important;
}
.li-list-drive{
    width: 90% !important;
    overflow: hidden;
    white-space: normal;
    word-wrap: break-word;
}

.input-label {
    text-decoration: dotted;
    font-weight: bold !important;
    font-size: 20px;
    text-align: center;
}

.footer {
    width: 90%;
    display: flex;
    flex-direction: row;
    flex: 100%;
    justify-content: space-between;
    gap: 20px;
    margin: 0 auto;
    padding-bottom: 20px;
    padding-top: 10px;
}
.footer input{
    height: 50px !important;
    font-size: 20px !important;
}
.footer button{
    font-size: 18px !important;
    width: 400px;
    border-radius: 10px;
}


.load-files{
    text-align: center;
}
.load-files p{
    color:#578fc4;
    font-size: 20px;
    font-weight: bold;   
}

.folder-select .span-advertencia-drive{
    padding-top: 10px;
    color: gray;
    width: 95%;
    text-align: center;
    margin: 0 auto;
}