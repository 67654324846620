.tableposprincipal{
    margin-top: -40px;
}  
.TablePointOfSales .containerCutBox{
    margin-top: -25px;
}
.TablePointOfSales .contanerFIltersTableSelect{
    margin-bottom: 20px;
}

.containerPointsale {
    display: grid;
    grid-template-areas: "expen1 expen2 expen3 expen4 expen5 expen8";
    gap: 10px;
    position: relative;
    width: 90%;
    z-index: 10;
    
  }