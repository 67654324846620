.TableProduct{
    width: 95% !important;
    margin: 0 auto;
    overflow-x: scroll;
}
.TableProduct .sticky-table-table{
    width: 100%;
}
.selectProduct{
    z-index: 150 !important;
}
.selectProduct:hover{
    cursor: pointer !important;
}