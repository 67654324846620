.grid-real-products{
    display: grid;
    width: 90%;
    /* margin: 0 auto; */
    grid-template-columns: 10% 10% 10% 10% 10% 10%;
    grid-template-areas: "a b c d e f";
    margin-left: 3%;
    grid-gap: 10px;
    align-items: center;
    position: relative;
    z-index: 150;
}
.grid-real-products input{
    height: 40px;
    font-size: 18px;
}
.grid-real-products select{
    height: 40px;
    font-size: 18px;
    padding: 5px;
}
.grp1{
    grid-area: "a";
}
.grp2{
    grid-area: "b";
}
.grp3{
    grid-area: "c";
}
.grp4{
    grid-area: "d";
}
.grp5{
    grid-area: "e";
}
.produ-table-real{
    margin: 0 auto;
}
.contend-table-prod{
    width: 100% !important;
}
.produ-table-real{
    width: 95% !important;
    margin-top: -55px;
}
.produ-table-real .sticky-table-table{
    width: 100%;
}

.produ-table-real td:nth-child(1){
    text-align: center;
}
.produ-table-real td:nth-child(2){
    text-align: left;
}
.tooltip-produc-real{
    width: 200px;
    padding: 10px;
}
.tooltip-produc-real p{
    width: 95%;
}
.filtros_clasificacion{
    width: 95%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 15% 20% 20% 15%;
    grid-area: "a b c d";
    grid-gap: 10px;
}
.fultro1-prod-real1{
    grid-area: "a";
}
.fultro1-prod-real2{
    grid-area: "b";
}