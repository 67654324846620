.support-ticket-modal {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-self: center;
  min-width: 400px;
  width: 800px !important;
  max-width: 100% !important;
  height: auto;
  max-height: 90%; 
  padding: 0;
  z-index: 0;
  text-align: center;
}


.ticket-details {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 15px;
  z-index: 2;
  background-color: white;
}

.ticket-items {
  display: flex;
  font-size: 1.1em;
  flex-direction: row;
  justify-content: space-between;
}

.ticket-items-rate {
  display: flex;
  font-size: 1.1em;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold; 
  font-size: medium;
  justify-content: center;
  margin-top: 15px;
}

.ticket-items-rate.hide{
  opacity: 0;
  display: none;
}

.detail-right {
  text-align: justify;
  width: 50%;
  padding: 4px 10px 4px 25px;
  border-left: 1px solid gray;
}

.detail-left {
  text-align: justify;
  width: 50%;
  padding: 4px 10px 4px 25px;
  border-right: 1px solid gray;
}

.rate_comment_container {
  width: 50%;
  height: fit-content;
  padding: 25px;
  display: flex;
  grid-template-columns: 95%;
  grid-template-rows: 30% 70%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.5);
  align-self: center;
  justify-content: space-around;
}

.rate {
  display: block;
  width: 100%;
  grid-area: grade;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 10px;
  font-weight:lighter;
  align-items: center;
  margin-bottom: 10px;
}

.rate_message {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rate_message .MuiTextField-root {
  flex: 0 0 80%;
}

.rate_message .MuiButton-root {
  flex: 1;
}


.comments-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0;
  height: 100%;
  background-color: #f2f2f2;
  overflow-y: auto;
  padding: 10px;
}


.reply{
  display: flex;
  flex-direction: column;
  flex: 80%;
  width: 90%;
}

.comment-content {
  border-radius: 5px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.5);
  padding: 7px;
  display: block;
}

.modal-buttons {
  position: sticky;
  display: flex;
  align-items: center;
  height: fit-content;
  margin-top: 20px;
  margin-bottom: 0;
  padding-inline: 50px;
  background-color: white;
  border-top: 1px solid rgb(153, 152, 152);
  padding-top: 10px;
  float: left;
  bottom: 0;
  z-index: 10;
}

.send-button {
  width: fit-content;
  margin-left: 5px;
}

.comment-individual{
    margin-top: 20px;
    margin-bottom: 20px;
}

.comment-response {
  top: 0%;
  border-left: 2px solid #ddd;
  margin-left: 70px;
  width: fit-content;
  padding-left: 15px;  
  padding: 10px;
}

.comment-response:hover {
  background-color: #f5f5f5;
}

.response-content {
  border-radius: 5px;  
  padding: 10px;
  box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.5);
}

.response-to {
  font-size: medium;
  font-style: italic;
  font-weight: bold;
  display: block;
  justify-content: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.comment-details {
  display: flex;
  justify-content: space-between;
}

.icon-details {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 16px;
  border-bottom: 1px solid grey;
  font-weight: bold;
  text-align: start;
  text-decoration: underline;
}

.icon-details:hover {
  cursor: pointer;
}

.icon-wrapper {
  position: relative;
  display: flex;
  width: 95%;
  height: fit-content; 
  color: rgb(143, 143, 143) ;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
}

.icon-1 {
  position: relative;
  z-index: 2;
  transform: rotate(-10deg);
  filter: drop-shadow(2px 2px 2px #ffffff);
}

.icon-2 {
  position: relative;
  top: 45px;
  margin-left:-40px;
  z-index: 1;
  transform: rotate(15deg);
  filter: drop-shadow(2px 2px 2px #ffffff);
}

.response-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #5d5d5d;
  filter: drop-shadow(2px 1px 1px #000000);  
  margin-right: -10px;
  z-index: 100;
}

.response-container {
  background-color: rgb(248, 248, 248);
  border-radius: 5px;
  padding: 1px 15px 1px 15px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.5);  
  transition: opacity 1s ease-in-out;
  transition-delay: 15s;
  align-self: flex-start;
  position: relative;
  margin-bottom: 25px !important;
  justify-content: center;
  font-size-adjust: initial;
  margin-top: 0;
  width: 90%;
  max-width: 100%;
}

.response-container.hide {
  opacity: 0;
  display: none;
}


.show-comments {
  text-decoration: underline;
  font-style: italic;
  font-size: 0.9em;
  text-transform: capitalize;
  left: 50px;
  color: #292828;
  font-weight: bold;
  width: fit-content;
}

.show-comments:hover{
  font-size: 0.95em;
}

.user-name-bold{
  font-weight: bold;
}

.editado{
  color: gray; 
  text-shadow: 2px 2px 1px rgba(156, 156, 156, 0.5); 
  font-style: oblique; 
  text-decoration: dotted;
  font-size: small;
}