

/* Tabla de roles  */
  .TableCommss{
    margin: 0 auto;
    width: 95% !important;
    margin-top: -50px;
  }
  .TableCommss .sticky-table-table{
    width: 100%;
  }
  .TableCommss td:nth-child(1){
    width: 10%;
    text-align: center;
  }
  .TableCommss td:nth-child(1) img{
    max-width: 30px;
    max-height: 30px;
  }
  
  .TableCommss td:nth-child(2),
  .TableCommss td:nth-child(3),
  .TableCommss td:nth-child(4)
  {
    text-align: left;
    white-space: normal;
    text-align: center;
  }
  
  .TableCommss th:nth-child(5),
  .TableCommss th:nth-child(6),
  .TableCommss th:nth-child(7),
  .TableCommss th:nth-child(8),
  .TableCommss th:nth-child(9),
  .TableCommss th:nth-child(10){
    /* max-width: 100px !important; */
    white-space: normal;
  }

  .TableCommss td:nth-child(5),
  .TableCommss td:nth-child(6),
  .TableCommss td:nth-child(7),
  .TableCommss td:nth-child(8),
  .TableCommss td:nth-child(9),
  .TableCommss td:nth-child(10){
    text-align: right;
  }

  
  .container-form-commss{
    width: 100%;
    margin: 0 auto;
  }
  .container-form-commss input{
    height: 60px;
    font-size: 20px;
  }
  .container-form-commss label{
    font-size: 20px;
  }

  .grid-commss1{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:
    "a b b c c d";
    grid-gap:10px;
  }
  .grid-commss2{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:
    "a a b b c c";
    grid-gap:10px;
  }
  .grid-commss3{
    margin: 0 auto;
    display: grid;
    width: 90%;
    grid-template-columns: 15% 15% 15% 15% 15% 15%;
    grid-template-areas:
    "a a b b c c";
    grid-gap:10px;
  }
  

  .commss1{grid-area: a;}
  .commss2{grid-area: b; position: relative;}
  .commss3{grid-area: c; position: relative;}
  .commssn{grid-area: d;}
  .commssn select{ height: 42px !important;}

  .commss4{grid-area: a;}
  .commss5{grid-area: b;}
  .commss6{grid-area: c;}

  .commss7{grid-area: a;}
  .commss8{grid-area: b;}
  .commss9{grid-area: c;}

  .commss1 select{
    font-size: 20px;
  }

  .commss2, .commss2{
    margin-left: 15px;
  }
  /* input fechas */
  .commss2 label, .commss3 label{
    position: absolute;
    top: 0;
    font-size: 17px;
    margin-left: 15px;
  }
  .commss2 input, .commss3 input{
    margin-top: 12px;
  }